<template>
	<div class="page-wrapper">
		<div class="page-header">
			<div class="page-header-title">
				<h1>Standard Operating Procedure For Cybercrime Investigation</h1>
			</div>

			<div class="action-buttons">
				<div class="button is-light" v-if="mode == 'EDIT'" @click="changeMode('VIEW')">
					<span class="icon mr-1">
						<icon icon="eye" />
					</span>
					View
				</div>
				<div class="button is-light" v-if="mode == 'VIEW'" @click="changeMode('EDIT')">
					<span class="icon mr-1">
						<icon icon="edit" />
					</span>
					Edit
				</div>
				<div class="button is-light" @click="deleteSOPs()">
					<span class="icon mr-1">
						<icon icon="trash" />
					</span>
					Delete
				</div>
				<div class="action-buttons button is-light" @click="saveSOPs()">Save</div>
			</div>
		</div>
		<div class="page-body">
			<div class="sop-title">
				<input type="text" class="input" placeholder="Enter SOP Title" v-model="data.title" />
			</div>
			<div class="tree-structure">
				<TreeView :data="treeData" class="tree-graph" v-if="mode == 'VIEW' && !isLoading && treeData" />
				<div class="tree-defination" v-if="mode == 'EDIT'">
					<tree-builder @getJSONData="handleJSONDATA" :initialTreeData="treeData" />
				</div>
			</div>

			<div class="content-wrapper">
				<textarea type="text" class="content editor-container" v-model="data.dataContent" v-if="mode == 'EDIT'" />

				<div class="content view-container" v-html="$md.parse(data.dataContent)" v-if="mode == 'VIEW'"></div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex/dist/vuex.cjs.js"
import axios from "axios"
import TreeView from "./TreeView.vue"
// import { VAceEditor } from "vue3-ace-editor"
import TreeBuilder from "./TreeBuilder.vue"
export default {
	components: {
		TreeView,
		TreeBuilder
		// VAceEditor
	},
	data() {
		return {
			data: {},
			content: `{
				key: "value"
			}`,
			mode: "VIEW",
			treeData: {},
			isLoading: false
		}
	},
	methods: {
		showSOPs() {
			console.log(this.data, !this.treeData, "MOUNTED")
		},
		deleteSOPs() {
			const { SOPID } = this.$route.params
			axios
				.delete(`/user/digital-artifacts/${SOPID}/delete`)
				.then(response => {
					if (response?.data?.success) {
						this.$router.push({ name: "SOPsList" })
					}
				})
				.catch(error => {
					console.log(error)
				})
		},
		saveSOPs() {
			console.log(this.treeData, "DATA")

			const { SOPID } = this.$route.params
			axios
				.put(`/user/digital-artifacts/${SOPID}/update`, { ...this.data, flowChart: this.treeData })
				.then(response => {
					if (response?.data?.success) {
						this.$router.push({ name: "SOPsList" })
					}
				})
				.catch(error => {
					console.log(error)
				})
		},
		handleJSONDATA(jsonData) {
			console.log(jsonData, "JSON DATA")
			this.treeData = jsonData
		},
		getSOPs() {
			const { SOPID } = this.$route.params

			this.isLoading = true

			axios
				.get(`/user/digital-artifacts/${SOPID}`)
				.then(response => {
					if (response?.data?.success) {
						this.data = response?.data?.response
						this.treeData = response?.data?.response?.flowChart
					}
					this.isLoading = false
				})
				.catch(error => {
					console.log(error)
					this.isLoading = false
				})
		},
		changeMode(mode) {
			this.mode = mode
			console.log(this.mode)
		},
		editorInit(editor) {
			// Set options for code folding and other features
			editor.renderer.setShowGutter(true) // Show line numbers
			editor.session.setFoldStyle("markbeginend") // Set fold style
			editor.session.setMode("ace/mode/html") // Set mode for syntax highlighting
			editor.setOptions({
				showGutter: true,
				showPrintMargin: false
			})
		}
	},
	computed: {
		...mapGetters(["sops"])
	},
	mounted() {
		this.getSOPs()
		this.showSOPs()
	}
}
</script>

<style lang="scss" scoped>
.page-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	padding: 1rem;
	.page-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1rem;
		.page-header-title {
			h1 {
				font-size: 1.5rem;
				font-weight: 700;
				color: #333;
			}
		}
		.action-buttons {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: $gap;
		}
	}
	.page-body {
		display: flex;
		// flex-grow: 1;
		// min-height: 500px;
		flex-direction: column;
		gap: $gap;
		flex: 1;

		.tree-structure {
			border: 1px solid #ccc;
			border-radius: 0.25rem;
			padding: 1rem;
			overflow: auto;

			display: flex;
			gap: $gap;

			.tree-graph {
				width: 100%;
				height: 500px;
			}
			.tree-defination {
				margin-top: 1rem;
				width: 100%;
				textarea {
					height: 200px;
					padding: 1rem;
				}
			}
		}

		.editor-container {
			flex: 1;
			border: 1px solid #ccc;
			border-radius: 0.25rem;
			padding: 1rem;
			height: 100vh;
			margin-bottom: 1rem;
		}
		.view-container {
			flex: 1;
			border: 1px solid #ccc;
			border-radius: 0.25rem;
			padding: 1rem;
			overflow: auto;
		}

		.sop-title {
			input {
				width: 30%;
				padding: 0.5rem;
			}
		}

		.content-wrapper {
			display: flex;
			flex: 1;
			gap: $gap;
		}
	}
}
</style>
