<template>
	<div ref="tree"></div>
</template>

<script>
import * as d3 from "d3"

export default {
	name: "CollapsibleTreeComponent",
	props: {
		data: {
			type: Object,
			required: false,
			default: null
		}
	},
	mounted() {
		this.createCollapsibleTree()
	},
	methods: {
		createCollapsibleTree() {
			console.log("Data: ", this.data)

			// if (Object.keys(this.data).length > 0) return

			const data = this.data
			const width = "100%"
			const height = 600
			const margin = { top: 20, right: 90, bottom: 30, left: 90 }

			const svg = d3
				.select(this.$refs.tree)
				.append("svg")
				.attr("width", width + margin.right + margin.left)
				.attr("height", height + margin.top + margin.bottom)
				.call(
					d3.zoom().on("zoom", event => {
						svg.attr("transform", event.transform)
					})
				)
				.append("g")
				.attr("transform", "translate(" + margin.left + "," + margin.top + ")")

			const root = d3.hierarchy(data, d => d.children)
			root.x0 = height / 2
			root.y0 = 0

			const treeLayout = d3.tree().size([height, width - 160])

			let i = 0

			const update = source => {
				const treeData = treeLayout(root)

				const nodes = treeData.descendants()
				const links = treeData.descendants().slice(1)

				nodes.forEach(d => {
					d.y = d.depth * 180
				})

				const node = svg.selectAll("g.node").data(nodes, d => d.id || (d.id = ++i))

				const nodeEnter = node
					.enter()
					.append("g")
					.attr("class", "node")
					.attr("transform", () => "translate(" + source.y0 + "," + source.x0 + ")")
					.on("click", (event, d) => {
						click(d)
					})

				nodeEnter
					.append("rect")
					.attr("class", "node")
					.attr("height", 30)
					.attr("y", -15)
					.style("fill", d => (d._children ? "lightsteelblue" : "#fff"))
					.style("stroke", "steelblue")
					.style("stroke-width", "1px")

				const textNode = nodeEnter
					.append("text")
					.attr("dy", ".35em")
					.attr("x", 0)
					.attr("y", 0)
					.attr("text-anchor", "middle")
					.text(d => d.data.name)

				// Adjust the rectangle dimensions based on the text width
				textNode.each(function (d) {
					console.log(d)
					const textElement = d3.select(this).node()
					const textWidth = textElement.getBBox().width
					const rectWidth = textWidth + 20 // Add some padding

					d3.select(this.parentNode)
						.select("rect")
						.attr("width", rectWidth)
						.attr("x", -rectWidth / 2) // Center the rectangle
				})

				const nodeUpdate = nodeEnter.merge(node)

				nodeUpdate
					.transition()
					.duration(200)
					.attr("transform", d => "translate(" + d.y + "," + d.x + ")")

				nodeUpdate.select("rect.node").style("fill", d => (d._children ? "lightsteelblue" : "#fff"))

				nodeUpdate.select("text").style("fill-opacity", 1)

				const nodeExit = node
					.exit()
					.transition()
					.duration(200)
					.attr("transform", () => "translate(" + source.y + "," + source.x + ")")
					.remove()

				nodeExit.select("rect").attr("width", 1e-6)
				nodeExit.select("text").style("fill-opacity", 1e-6)

				const link = svg.selectAll("path.link").data(links, d => d.id)

				const linkEnter = link
					.enter()
					.insert("path", "g")
					.attr("class", "link")
					.attr("style", "fill: none; stroke: #ccc; stroke-width: 1.5px")
					.attr("d", () => {
						const o = { x: source.x0, y: source.y0 }
						return diagonal(o, o)
					})

				const linkUpdate = linkEnter.merge(link)

				linkUpdate
					.transition()
					.duration(200)
					.attr("d", d => diagonal(d, d.parent))

				link
					.exit()
					.transition()
					.duration(200)
					.attr("d", () => {
						const o = { x: source.x, y: source.y }
						return diagonal(o, o)
					})
					.remove()

				nodes.forEach(d => {
					d.x0 = d.x
					d.y0 = d.y
				})

				function diagonal(s, d) {
					return d3
						.linkHorizontal()
						.x(d => d.y)
						.y(d => d.x)({ source: s, target: d })
				}

				function click(d) {
					if (d.children) {
						d._children = d.children
						d.children = null
					} else {
						d.children = d._children
						d._children = null
					}
					update(d)
				}
			}

			update(root)
		}
	}
}
</script>

<style scoped>
.node {
	cursor: pointer;
}

.node rect {
	fill: #fff;
	stroke: steelblue;
	stroke-width: 3px;
}

.node text {
	font: 12px sans-serif;
	fill: black;
}

.link {
	fill: none;
	stroke: #ccc;
	stroke-width: 1.5px; /* Make the links thinner */
}
</style>
