<template>
	<div class="page-wrapper">
		<div class="page-header">
			<div class="page-header-title">
				<h1>Standard Operating Procedure For Cybercrime Investigation</h1>
			</div>
		</div>
		<div class="page-body">
			<!-- <ol>
				<li v-for="sop in sops" :key="sop.id">
					<router-link :to="{ name: 'SOPsView', params: { SOPID: sop.id } }">{{ sop.title }}</router-link>
				</li>
				<div class="add-new-sop">
					<router-link :to="{ name: 'SOPsAdd' }">Add New SOP</router-link>
				</div>
			</ol> -->

			<div class="header-wrapper">
				<div class="button-item">
					<router-link :to="{ name: 'SOPsAdd' }" class="btn btn-primary">
						<span>
							<icon icon="plus" />
						</span>
						Add SOP</router-link
					>
				</div>
			</div>

			<BTable ref="sops-list" :tableConfig="SOPsTableConfig" :tableData="SOPsTableData" :has-actions="true">
				<template v-slot="{ row }">
					<BTableRowItem class="user-info-wrapper">
						<!-- Name  -->
						<p class="name">{{ row.sopTitle }}</p>
					</BTableRowItem>
					<BTableRowItem :value="row?.createdBy" type="plaintext" />
					<BTableRowItem :value="formatTimestamp(row?.createdAt)" type="plaintext" />
					<!-- {{ row }} -->
					<!-- <BTableRowItem :value="row?.farUtilized" type="plaintext" /> -->
				</template>
			</BTable>
		</div>
	</div>
</template>

<script>
import BTable from "@/components/BTable.vue"
import BTableRowItem from "@/components/BTableRowItem.vue"
import CONSTANTS from "@/assets/js/constants"
import { mapGetters } from "vuex/dist/vuex.cjs.js"
import axios from "axios"

export default {
	components: {
		BTable,
		BTableRowItem
	},
	data() {
		return {
			TABLE_STATES: CONSTANTS.TABLE_STATES,
			data: "",
			SOPsTableConfig: [
				{
					title: "Digital Artifacts"
				},
				{
					title: "Created By"
				},
				{
					title: "Created At"
				}
			],
			SOPsTableData: [
				// {
				// 	sopTitle: "Mobile Number",
				// 	createdBy: "Admin",
				// 	createdAt: "2021-09-01",
				// 	actions: [
				// 		{
				// 			type: "router-link",
				// 			route: "SOPsView",
				// 			key: "SOPsView",
				// 			paramId: "SOPID",
				// 			id: "1",
				// 			title: "view",
				// 			icon: "eye"
				// 		}
				// 	]
				// },
				// {
				// 	sopTitle: "Email Address",
				// 	createdBy: "Admin",
				// 	createdAt: "2021-09-01",
				// 	actions: [
				// 		{
				// 			type: "router-link",
				// 			route: "SOPsView",
				// 			key: "SOPsView",
				// 			paramId: "SOPID",
				// 			id: "2",
				// 			title: "view",
				// 			icon: "eye"
				// 		}
				// 	]
				// },
				// {
				// 	sopTitle: "IP Address",
				// 	createdBy: "Admin",
				// 	createdAt: "2021-09-01",
				// 	actions: [
				// 		{
				// 			type: "router-link",
				// 			route: "SOPsView",
				// 			key: "SOPsView",
				// 			paramId: "SOPID",
				// 			id: "3",
				// 			title: "view",
				// 			icon: "eye"
				// 		}
				// 	]
				// }
			]
		}
	},
	methods: {
		fetchSOPs() {
			// this.$store.dispatch("fetchSOPs")
			let { LOADING, ACTIVE, EMPTY, SERVER_ERROR } = this.TABLE_STATES
			this.SOPsTableData = this.sops

			this.$refs["sops-list"]?.setCurrentState(LOADING)
			axios
				.get("/user/digital-artifacts/list")
				.then(response => {
					if (response.data.success) {
						if (response.data.response.length > 0) {
							this.SOPsTableData = this.processTableData(response.data.response)
							this.$refs["sops-list"]?.setCurrentState(ACTIVE)
						} else {
							this.$refs["sops-list"]?.setCurrentState(EMPTY)
						}
					} else {
						this.$refs["sops-list"]?.setCurrentState(SERVER_ERROR)
					}
				})
				.catch(() => {
					this.$refs["sops-list"]?.setCurrentState(SERVER_ERROR)
				})
		},
		processTableData(data) {
			return data.map(item => {
				return {
					sopTitle: item.title,
					createdBy: item.createdBy,
					createdAt: item.createdAt,
					actions: [
						{
							type: "router-link",
							route: "SOPsView",
							key: "SOPsView",
							paramId: "SOPID",
							id: item._id,
							title: "view",
							icon: "eye"
						}
					]
				}
			})
		},
		formatTimestamp(timestamp) {
			const date = new Date(Number(timestamp))
			const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
			const month = months[date.getMonth()]
			const day = date.getDate()
			const year = date.getFullYear()
			let hours = date.getHours()
			const minutes = String(date.getMinutes()).padStart(2, "0")
			const period = hours >= 12 ? "pm" : "am"
			hours = hours % 12 || 12 // Convert hours to 12-hour format

			return `${month} ${day}${this.getOrdinalSuffix(day)} ${year}, ${hours}:${minutes}${period}`
		},

		getOrdinalSuffix(day) {
			if (day > 3 && day < 21) return "th"
			switch (day % 10) {
				case 1:
					return "st"
				case 2:
					return "nd"
				case 3:
					return "rd"
				default:
					return "th"
			}
		}
	},
	mounted() {
		this.fetchSOPs()
	},
	computed: {
		...mapGetters(["sops"])
	}
}
</script>

<style lang="scss" scoped>
.page-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	padding: 1rem;
	.page-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1rem;
		.page-header-title {
			h1 {
				font-size: 1.5rem;
				font-weight: 700;
				color: #333;
			}
		}
	}
	.page-body {
		display: flex;
		// flex-grow: 1;
		min-height: 500px;
		padding: 1rem;
		flex-direction: column;
		gap: $gap;
		.editor-container {
			flex: 1;
			margin-right: 1rem;
			border: 1px solid #ccc;
			border-radius: 0.25rem;
			padding: 1rem;
		}
		.view-container {
			flex: 1;
			border: 1px solid #ccc;
			border-radius: 0.25rem;
			padding: 1rem;
			overflow: auto;
		}
		.header-wrapper {
			display: flex;
			justify-content: end;

			.button-item {
				border: 1px solid #ccc;
				padding: calc($gap);
				border-radius: calc($gap / 2);
				background: hsl(0, 0%, 95%);

				a {
					text-decoration: none;
					color: #333;
				}

				&:hover {
					background: hsl(0, 0%, 90%);
				}
			}
		}
	}
}
</style>
