<template>
	<div class="techniques content">
		<div class="technique-hero">
			<div class="technique-hero-header">
				<div class="hero-header-title">
					<InputBox class="input-box" :data="selectedTechniqueInfo.title" type="plaintext" :is-editable="canEditSection()"
						@updated="updateSelectedTechniqueInfo('title', $event)" />
				</div>
				<div class="hero-header-actions">
					<!-- Preview  -->
					<div class="action-item" @click="setViewMode()">
						<div class="icon" v-if="isTechniqueEditable === modes.edit">
							<icon class="icon" icon="eye" />
						</div>
						<div class="icon" v-if="isTechniqueEditable === modes.preview">
							<icon class="icon" icon="edit" />
						</div>
					</div>
					<!-- Download  -->
					<div class="action-item" @click="downloadJSON">
						<div class="icon">
							<icon class="icon" icon="download" />
						</div>
					</div>
				</div>
			</div>
			<div class="technique-hero-body">
				<div class="hero-body-item subtechnique">
					<div class="subtechnique-wrapper">
						<div class="subtechnique-header">
							<div class="header-title">Subtechniques</div>
							<div class="header-actions">
								<button class="button is-rounded" @click="viewSubtechniques = !viewSubtechniques">
									<icon icon="chevron-down" :class="viewSubtechniques ? 'fa-flip-vertical' : ''" />
								</button>
							</div>
						</div>
						<div class="subtechnique-body" v-if="viewSubtechniques">
							<ul>
								<li v-for="subtechnique in selectedTechniqueInfo.subtechniques" :key="subtechnique">
									{{ subtechnique }}
								</li>
							</ul>
						</div>
					</div>
					<div class="subtechnique-description">
						<div class="subtechnique-title">Description</div>
						<InputBox :data="selectedTechniqueInfo.desc" type="markdown" :is-editable="canEditSection()"
							field-type="textarea" @updated="updateSelectedTechniqueInfo('desc', $event)" />
					</div>
				</div>
				<div class="hero-body-item version">
					<div class="version-item">
						Version: 1.1 <br />
						08 October 2021 <br />
						Last Modified: 12 February 2023
						<br />
					</div>
				</div>
			</div>
		</div>
		<section class="technique-body">
			<section class="technique-body-item defend-wrapper">
				<div class="defend-item" v-for="section in selectedTechniqueInfo?.sectionsData" :key="section">
					<TechniqueDefend :section="section"> {{ section.title }} </TechniqueDefend>
				</div>
			</section>

			<section class="technique-body-item references">
				<div class="references-header">
					<div class="references-header-title">References</div>
				</div>

				<div class="references-body" v-if="canEditSection()">
					<InputBox :data="selectedTechniqueInfo.reference_links" type="markdown" :is-editable="canEditSection()"
						field-type="textarea" @updated="updateSelectedTechniqueInfo('reference_links', $event)" />
				</div>
				<div class="references-body" v-else v-html="$md.parse(selectedTechniqueInfo?.reference_links)"></div>
			</section>
		</section>
	</div>
</template>

<script>
import TechniqueDefend from "@/components/TechniqueDefend.vue"
import InputBox from "@/components/InputBox.vue"

// Store data
import { mapActions, mapGetters } from "vuex"

export default {
	components: { TechniqueDefend, InputBox },

	data() {
		return {
			title: null,
			viewSubtechniques: false,
			activeMode: 1,
			modes: {
				edit: 0,
				preview: 1
			}
		}
	},
	methods: {
		...mapActions(["setSelectedTechniqueItem", "setSelectedTechniqueMode"]),
		updateSelectedTechniqueInfo(target, event) {
			this.setSelectedTechniqueItem({ target, event })
		},
		canPreviewSection() {
			let { activeMode, modes } = this
			if (activeMode == modes.preview) return true
		},
		canEditSection() {
			let { isTechniqueEditable, modes } = this
			if (isTechniqueEditable == modes.edit) return true
		},
		isSectionVisible(section) {
			return this.isTechniqueEditable ? this.isTechniqueEditable : !!this.selectedTechniqueInfo[section.key]
		},
		setViewMode() {
			let { modes, isTechniqueEditable, setSelectedTechniqueMode } = this

			switch (isTechniqueEditable) {
				case modes.edit:
					setSelectedTechniqueMode(this.modes.preview)
					break
				case modes.preview:
					setSelectedTechniqueMode(this.modes.edit)
					break

				default:
					break
			}
		},
		downloadJSON() {
			let filename = this.$route.params.techniqueID
			let data = JSON.stringify(this.selectedTechniqueInfo, null, 2);

			const blob = new Blob([data], { type: 'application/json' });
			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');

			link.href = url;
			link.download = `${filename}.json`;
			link.click();
			URL.revokeObjectURL(url);
		}
	},
	computed: {
		...mapGetters(["selectedTechniqueInfo", "isTechniqueEditable"])
	}
}
</script>

<style lang="scss" scoped>
$base-background: hsl(0, 0%, 95%);

.techniques {
	display: flex;
	flex-direction: column;
	gap: $gap;

	.technique-hero {
		display: flex;
		flex-direction: column;
		gap: $gap;

		.technique-hero-header {
			display: flex;
			gap: $gap;
			justify-content: space-between;
			align-items: center;
			height: 72px;

			.hero-header-title {
				flex-grow: 1;

				height: 100%;
				font-size: 25px !important;
				font-weight: 700 !important;

				border-radius: $gap;
				// padding: calc($gap * 2);
				background-color: transparent;
				border: none;

				.input-box .edit-mode .input {
					font-size: 25px !important;
					font-weight: 700 !important;
				}

				&:focus-visible {
					outline: 0;
				}
			}

			.hero-header-actions {
				height: 100%;
				display: flex;
				justify-content: flex-end;
				border-radius: $gap;
				gap: $gap;
				// padding: calc($gap);
				justify-content: center;
				align-items: center;
				overflow: hidden;
				background-color: $base-background;

				.action-item {
					height: 100%;
					width: 100%;
					display: flex;
					padding: calc($gap * 2);
					align-items: center;
					// border-radius: $gap;
					justify-content: center;

					&:hover {
						cursor: pointer;
						background-color: hsl(0, 0%, 85%);
					}

					.icon {
						height: 100%;
						width: 100%;
						// background-color: $base-background;
						// padding: calc($gap * 2);
						border-radius: calc($gap);

						svg {
							max-height: 24px;
							max-width: 24px;
						}
					}
				}
			}
		}

		.technique-hero-body {
			display: flex;
			gap: $gap;
			border-radius: $gap;

			.hero-body-item {
				&.subtechnique {
					display: flex;
					flex-direction: column;
					gap: $gap;
					width: 75%;

					.subtechnique-wrapper {
						display: flex;
						flex-direction: column;
						gap: 0;
						padding: $gap calc($gap * 2);
						border-radius: $gap;
						background-color: $base-background;

						.subtechnique-header {
							display: flex;
							justify-content: space-between;
							align-items: center;

							.header-title {
								font-size: 20px;
								font-weight: 600;
							}

							.header-actions {
								.button {
									height: 44px;
									width: 44px;
								}
							}
						}

						.subtechnique-body {
							ul {
								margin-top: 0;
							}
						}
					}

					.subtechnique-description {
						padding: calc($gap * 2);
						border-radius: $gap;
						background-color: $base-background;

						.subtechnique-title {
							font-size: 20px;
							font-weight: 700;
						}
					}
				}

				&.version {
					width: 25%;
					padding: calc($gap * 2);
					border-radius: $gap;
					background-color: $base-background;
				}
			}
		}
	}

	.technique-body {
		display: flex;
		flex-direction: column;
		gap: $gap;
		border-radius: $gap;

		.technique-body-item {
			display: flex;
			flex-direction: column;
			gap: $gap;
			border-radius: $gap;
			overflow-x: hidden;
			// background-color: $base-background; // REMOVE
			// padding: 1rem; // REMOVE

			&.defend-wrapper {
				display: flex;
				flex-direction: column;
				gap: $gap;

				.defend-item {
					background-color: $base-background; // REMOVE
					padding: 1rem; // REMOVE
					border-radius: $gap; // REMOVE
				}
			}

			&.references {
				background-color: $base-background; // REMOVE
				padding: 1rem; // REMOVE
				border-radius: $gap; // REMOVE

				.references-header {
					.references-header-title {
						font-size: 20px;
						font-weight: 700;
					}
				}
			}
		}
	}
}
</style>
