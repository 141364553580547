<template>
	<div class="tree-builder">
		<h1 class="header-item">Tree Builder</h1>

		<div class="update body-item">
			<label for="selectedNodeName">Selected Node:</label>
			<div class="wrapper-input">
				<input type="text" class="input" v-model="selectedNodeName" placeholder="Update Name" />
				<button class="button is-light" @click="updateNode">Update Node</button>
				<button class="button is-light" @click="deleteNode">Delete Node</button>
			</div>
		</div>
		<div class="body-item">
			<input type="text" class="input" v-model="newNodeName" placeholder="Node Name" />
			<button class="button is-light" @click="addNode">Add Node</button>
		</div>
		<div class="tree-container" ref="treeContainer"></div>
		<!-- <div>
			<button @click="getJSON">Get JSON</button>
			<pre>{{ jsonOutput }}</pre>
		</div> -->
	</div>
</template>

<script>
import * as d3 from "d3"

export default {
	data() {
		return {
			treeData: null,
			newNodeName: "",
			jsonOutput: "",
			selectedNode: null,
			selectedNodeName: ""
		}
	},
	props: {
		initialTreeData: {
			type: Object,
			required: false,
			default: null
		}
	},
	methods: {
		addNode() {
			if (this.newNodeName.trim()) {
				if (!this.treeData) {
					this.treeData = { name: this.newNodeName, children: [] }
					this.newNodeName = ""
					this.updateTree()
					return
				}
				const newNode = { name: this.newNodeName, children: [] }
				if (this.selectedNode) {
					console.log("Selected Node: ", this.selectedNode)
					this.selectedNode.data.children.push(newNode)
				} else {
					console.log("Selected Node Tree Data: ", this.treeData.data)
					this.treeData.data.children.push(newNode)
				}
				this.newNodeName = ""
				this.updateTree()
			}
		},
		updateNode() {
			if (this.selectedNode) {
				this.selectedNode.data.name = this.selectedNodeName
				this.newNodeName = ""
				this.updateTree()
			}
		},
		deleteNode() {
			if (this.selectedNode) {
				console.log("Selected Node: ", this.selectedNode)
				const parent = this.selectedNode?.parent?.data
				const index = parent.children.indexOf(this.selectedNode?.data)
				console.log("Index: ", index)
				parent.children.splice(index, 1)
				this.selectedNode = null
				this.selectedNodeName = ""
				console.log("Parent: ", parent, this.treeData)
				this.updateTree()
			}
		},
		updateTree() {
			const width = "100%"
			const height = 600
			const margin = { top: 20, right: 90, bottom: 30, left: 90 }

			d3.select(this.$refs.treeContainer).selectAll("*").remove()

			const svg = d3
				.select(this.$refs.treeContainer)
				.append("svg")
				.attr("width", width + margin.right + margin.left)
				.attr("height", height + margin.top + margin.bottom)
				.call(
					d3.zoom().on("zoom", event => {
						svg.attr("transform", event.transform)
					})
				)
				.append("g")
				.attr("transform", "translate(" + margin.left + "," + margin.top + ")")

			const root = d3.hierarchy(this.treeData)
			root.x0 = height / 2
			root.y0 = 0

			const treeLayout = d3.tree().size([height, width - 160])

			let i = 0

			const update = source => {
				const treeData = treeLayout(root)

				const nodes = treeData.descendants()
				const links = treeData.descendants().slice(1)

				nodes.forEach(d => {
					d.y = d.depth * 180
				})

				const node = svg.selectAll("g.node").data(nodes, d => d.id || (d.id = ++i))

				const nodeEnter = node
					.enter()
					.append("g")
					.attr("class", "node")
					.attr("transform", () => "translate(" + source.y0 + "," + source.x0 + ")")
					.on("click", (event, d) => {
						click(d)

						this.selectNode(d)
					})

				nodeEnter
					.append("rect")
					.attr("class", "node")
					.attr("height", 30)
					.attr("y", -15)
					.style("fill", d => (d._children ? "lightsteelblue" : "#fff"))
					.style("stroke", "steelblue")
					.style("stroke-width", "1px")

				const textNode = nodeEnter
					.append("text")
					.attr("dy", ".35em")
					.attr("x", 0)
					.attr("y", 0)
					.attr("text-anchor", "middle")
					.text(d => d.data.name)

				// Adjust the rectangle dimensions based on the text width
				textNode.each(function (d) {
					console.log(d)
					const textElement = d3.select(this).node()
					const textWidth = textElement.getBBox().width
					const rectWidth = textWidth + 20 // Add some padding

					d3.select(this.parentNode)
						.select("rect")
						.attr("width", rectWidth)
						.attr("x", -rectWidth / 2) // Center the rectangle
				})

				const nodeUpdate = nodeEnter.merge(node)

				nodeUpdate
					.transition()
					.duration(200)
					.attr("transform", d => "translate(" + d.y + "," + d.x + ")")

				nodeUpdate.select("rect.node").style("fill", d => (d._children ? "lightsteelblue" : "#fff"))

				// nodeUpdate.on("click", function () {
				// 	d3.select(this).select("rect").style("fill", "lightsteelblue")
				// })

				nodeUpdate.select("text").style("fill-opacity", 1)

				const nodeExit = node
					.exit()
					.transition()
					.duration(200)
					.attr("transform", () => "translate(" + source.y + "," + source.x + ")")
					.remove()

				nodeExit.select("rect").attr("width", 1e-6)
				nodeExit.select("text").style("fill-opacity", 1e-6)

				const link = svg.selectAll("path.link").data(links, d => d.id)

				const linkEnter = link
					.enter()
					.insert("path", "g")
					.attr("class", "link")
					.attr("style", "fill: none; stroke: #ccc; stroke-width: 1.5px")
					.attr("d", () => {
						const o = { x: source.x0, y: source.y0 }
						return diagonal(o, o)
					})

				const linkUpdate = linkEnter.merge(link)

				linkUpdate
					.transition()
					.duration(200)
					.attr("d", d => diagonal(d, d.parent))

				link
					.exit()
					.transition()
					.duration(200)
					.attr("d", () => {
						const o = { x: source.x, y: source.y }
						return diagonal(o, o)
					})
					.remove()

				nodes.forEach(d => {
					d.x0 = d.x
					d.y0 = d.y
				})

				function diagonal(s, d) {
					return d3
						.linkHorizontal()
						.x(d => d.y)
						.y(d => d.x)({ source: s, target: d })
				}

				function click(d) {
					if (d.children) {
						d._children = d.children
						d.children = null
					} else {
						d.children = d._children
						d._children = null
					}
					update(d)
				}
			}

			update(root)
			this.$emit("getJSONData", this.treeData)
		},
		selectNode(node) {
			this.selectedNode = node
			this.selectedNodeName = node.data.name

			console.log("Selected Node: ", this.selectedNode)
		},
		getJSON() {
			this.jsonOutput = JSON.stringify(this.treeData, null, 2)
		}
	},
	mounted() {
		console.log("Initial Tree Data: ", Object.keys(this.initialTreeData).length === 0)
		this.treeData = Object.keys(this.initialTreeData).length === 0 ? null : this.initialTreeData
		if (!this.treeData) {
			return
		}

		console.log("Initial Tree Data: ", this.treeData)
		this.updateTree()
	}
}
</script>

<style lang="scss" scoped>
/* div {
	margin: 10px;
}
input {
	margin-right: 10px;
} */
.input {
	width: 300px;
}
pre {
	background-color: #f4f4f4;
	padding: 10px;
}

.node {
	cursor: pointer;
}

.node rect {
	fill: #fff;
	stroke: steelblue;
	stroke-width: 3px;
}

.node text {
	font: 12px sans-serif;
	fill: black;
}

.link {
	fill: none;
	stroke: #ccc;
	stroke-width: 1.5px; /* Make the links thinner */
}

.tree-builder {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.header-item {
	font-size: 24px;
}

.add-node {
	background-color: #4caf50;
	border: none;
	color: white;
	cursor: pointer;
}

.body-item {
	display: flex;
	gap: 0.5rem;
	width: 50%;

	.wrapper-input {
		display: flex;
		gap: 0.5rem;
	}

	&.update {
		flex-direction: column;
	}
}

.tree-container {
	border: 1px solid #ccc;
	border-radius: 0.25rem;
	padding: 1rem;
	overflow: auto;
	height: 600px;
}
</style>
