<template>
	<section class="technique-base-view">
		<NavigatorBaseHeader />
		<section class="navigator-body">
			<div class="technique-base-item technique-base-sidebar">
				<TechniqueSidebar @route-updated="refreshTechniqueData($event)" />
			</div>
			<div class="technique-base-item technique-base-body">
				<TechniqueBody @edited="updateInfo($event)" />
			</div>
		</section>
	</section>
</template>

<script>
import axios from "axios"
import CONSTANTS from "@/assets/js/constants.js"

import NavigatorBaseHeader from "@/views/navigator/NavigatorBaseHeader.vue"
import TechniqueSidebar from "@/components/TechniqueSidebar.vue"
import TechniqueBody from "@/components/TechniqueBody.vue"

// Store
import { mapActions, mapGetters } from "vuex"
export default {
	components: {
		NavigatorBaseHeader,
		TechniqueSidebar,
		TechniqueBody
	},
	data() {
		return {
			baseTechniqueConfig: CONSTANTS.baseTechniqueConfig
		}
	},
	methods: {
		...mapActions(["setSelectedTechniqueInfo", "setSelectedTechniqueSelectedItems"]),
		updateRoute({ tacticID, techniqueID }) {
			this.refreshTechniqueData({ tacticID, techniqueID })
		},
		fetchTechniqueData() {
			let { tacticID, techniqueID } = this.$route.params
			// let BASE_URL = process.env.VUE_APP_BASE_URL
			let path = `/assets/tactics-data/${tacticID}/${techniqueID}/index.json`

			axios.get(path).then(response => {
				let { data } = response
				this.setSelectedTechniqueInfo(data)
				this.processSectionData()
			})
		},
		processSectionData() {
			let result = []
			let { baseTechniqueConfig, selectedTechniqueInfo } = this

			baseTechniqueConfig.forEach(config => {
				let { key, title } = config

				// if (!selectedTechniqueInfo[key]) return

				let payload = {
					key: key,
					title: title,
					data: selectedTechniqueInfo[key]
				}

				result.push(payload)
			})

			this.setSelectedTechniqueSelectedItems(result)
		},
		refreshTechniqueData({ tacticID, techniqueID }) {
			// let BASE_URL = process.env.VUE_APP_BASE_URL
			let path = `/assets/tactics-data/${tacticID}/${techniqueID}/index.json`
			axios.get(path).then(response => {
				let { data } = response
				this.setSelectedTechniqueInfo(data)
				this.processSectionData()
			})
		},
		updateInfo(event) {
			this.selectedTechniqueInfo.title = event?.title
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.fetchTechniqueData()
		})
	},
	computed: {
		...mapGetters(["selectedTechniqueInfo"])
	}
}
</script>

<style lang="scss" scoped>
$default-padding: calc($gap * 2);

.technique-base-view {
	display: flex;
	flex-direction: column;

	height: 100%;
	width: 100%;

	.navigator-body {
		display: flex;
		flex-direction: row;
		flex-grow: 1;

		overflow: hidden;
	}

	.technique-base-item {
		height: 100%;
		width: 100%;

		&.technique-base-sidebar {
			max-width: 20%;
			min-width: 350px;
			width: 100%;
			box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
		}

		&.technique-base-body {
			flex-grow: 1;
			height: 100%;
			width: 100%;
			background-color: transparent;
			padding: $default-padding;
			overflow: auto;
		}
	}
}

@media screen and (max-width: 768px) {
	.technique-base-sidebar {
		display: none;
	}
}
</style>
