<template>
	<aside class="technique-sidebar">
		<div class="tactics-header">
			<div class="sidebar-caption">Tactics</div>
		</div>
		<div class="tactics-body">
			<div class="tactic" v-for="(tactic, tactic_index) in navigatorData" :key="tactic">
				<div class="tactic-header" @click="toggleTactic(tactic_index)">
					<div class="tactic-header-title">{{ tactic.title }}</div>
					<div class="tactic-header-icon">
						{{ tactic.isSelected ? "-" : "+" }}
					</div>
				</div>
				<div class="tactic-body" v-if="isTacticSelected(tactic_index)">
					<div class="techniques" v-if="tactic.techniques?.length">
						<div
							@click="openTechnique(technique, tactic_index)"
							class="technique"
							v-for="technique in tactic.techniques"
							:key="technique"
							:class="{ 'is-selected-active': isTechniqueSelected(technique.id) }"
							:ref="technique.id"
							:id="technique.id"
						>
							{{ technique.title }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</aside>
</template>

<script>
// import navigatorData from "@/assets/js/navigator-data.js"
import { mapState } from "vuex"
export default {
	data() {
		return {}
	},
	methods: {
		toggleTactic(index) {
			this.navigatorData[index].isSelected = !this.navigatorData[index].isSelected
		},
		openTechnique(technique, tactic_index) {
			let techniqueID = technique.id
			let tacticID = tactic_index < 9 ? "0" + (tactic_index + 1) : tactic_index + 1

			tacticID = tacticID.toString()

			this.$router.push({ name: "TechniqueBaseView", params: { tacticID, techniqueID } })
			this.$emit("route-updated", { tacticID, techniqueID })

			this.$nextTick(() => {
				this.scrollTechniqueIntoView()
			})
		},
		resolveTacticID(tactic_index) {
			let result
			if (tactic_index < 9) result = "0" + (tactic_index + 1)
			else result = tactic_index + 1

			return result.toString()
		},
		isTacticSelected(tactic_index) {
			let { isSelected } = this.navigatorData[tactic_index]

			return isSelected
		},
		isTechniqueSelected(id) {
			let { techniqueID } = this.$route.params
			return techniqueID == id
		},
		selectActiveTechnique() {
			let { parseTacticIndex, parseTechniqueIndex, selectActiveTactic, scrollTechniqueIntoView } = this
			let { tacticID, techniqueID } = this.$route.params
			let tactic_index = parseTacticIndex(tacticID)
			let technique_index = parseTechniqueIndex(tactic_index, techniqueID)

			this.navigatorData[tactic_index].techniques[technique_index].isSelected = true
			selectActiveTactic(tactic_index)
			scrollTechniqueIntoView()
		},
		selectActiveTactic(tactic_index) {
			this.navigatorData[tactic_index].isSelected = true
		},
		scrollTechniqueIntoView() {
			let { techniqueID } = this.$route.params

			this.$nextTick(() => {
				let doc = document.getElementById(techniqueID)

				setTimeout(() => {
					doc.scrollIntoView({ behavior: "smooth", block: "nearest" })
				}, 100)
			})
		},
		parseTacticIndex(tacticID) {
			let tactic_index = parseInt(tacticID) - 1
			return tactic_index
		},
		parseTechniqueIndex(tactic_index, techniqueID) {
			let techniques = this.navigatorData[tactic_index].techniques
			let index

			index = techniques.findIndex(technique => technique.id === techniqueID)

			return index
		}
	},
	computed: {
		...mapState(["navigatorData"])
	},
	mounted() {
		this.selectActiveTechnique()
	}
}
</script>

<style lang="scss" scoped>
aside.technique-sidebar {
	display: flex;
	flex-direction: column;
	gap: calc($gap/2);

	height: 100%;
	width: 100%;
	overflow: scroll;
	padding: $gap;

	.tactics-header {
		display: flex;
		flex-direction: column;
		gap: calc($gap/2);
		padding: 0 $gap;

		.sidebar-caption {
			font-size: 12px;
			text-transform: uppercase;
			font-weight: 800;
			color: hsl(0, 0%, 70%);
			user-select: none;
		}
	}

	.tactics-body {
		display: flex;
		flex-direction: column;
		gap: $gap;

		.tactic {
			display: flex;
			flex-direction: column;
			gap: calc($gap/2);

			.tactic-header {
				display: flex;
				gap: $gap;
				justify-content: space-between;

				color: $base-text-color-light;
				user-select: none;

				.tactic-header-title {
					width: 100%;
					font-weight: 700;
					font-size: 16px;
				}

				.tactic-header-icon {
					height: 20px;
					width: 20px;
					padding: 5px;
					border-radius: 1000px;

					display: flex;
					justify-content: center;
					align-items: center;

					font-weight: 600;
					font-size: 18px;
				}

				padding: $gap;
				border-radius: calc($gap/2);

				&:hover {
					cursor: pointer;
					background-color: hsl(0, 0%, 95%);
				}
			}
			.tactic-body {
				padding-left: calc($gap * 2);
				border-radius: $gap;
				.techniques {
					display: flex;
					flex-direction: column;
					gap: calc($gap/2);
					.technique {
						padding: $gap;
						border-radius: calc($gap/2);

						&:hover {
							cursor: pointer;
							background-color: hsl(0, 0%, 95%);
						}

						&.is-selected-active {
							// font-weight: 600;
							background-color: hsl($background-h, $background-s, 94%);
						}
					}
				}
			}
		}
	}
}
</style>
