<template>
	<div class="table-wrapper" :class="[{ 'has-shadow': hasShadow }]">
		<table v-if="canShowTable">
			<thead>
				<tr>
					<th v-for="(header, index) in tableConfig" :key="index" :class="[header.class]" :width="header.width">
						<div class="t-header">
							<span class="header-title">{{ header.title }}</span>
							<span class="header-subtitle" v-if="header.subtitle">{{ header.subtitle }}</span>
						</div>
					</th>
					<th class="has-text-centered" v-if="hasActions">Actions</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(row, rowIndex) in tableData" :key="rowIndex">
					<!-- Default slot  -->
					<slot :row="row" :index="rowIndex"></slot>

					<!-- Actions  -->
					<td class="table-row-item actions-wrapper" v-if="hasActions">
						<router-link
							class="action-item button"
							:class="action.class"
							v-for="action in row.actions"
							:key="action"
							:to="{
								name: action.route,
								params: { [action.paramId]: action.id, [action.secondaryParamId]: action.secondaryId }
							}"
						>
							<span class="icon">
								<icon :icon="action.icon" />
							</span>
							<span v-if="action.title">{{ action.title }}</span>
						</router-link>
					</td>
				</tr>
			</tbody>
		</table>
		<!-- Loading State -->
		<div class="reloading" v-if="currentState === TABLE_STATES.LOADING">
			<button class="button is-loading"></button>
		</div>
		<!-- Reloading State -->
		<div class="reloading fade-in-up" v-if="currentState === TABLE_STATES.RELOADING">
			<button class="button is-loading"></button>
		</div>
		<!-- Empty State -->
		<div class="is-empty" v-if="currentState === TABLE_STATES.EMPTY">
			<span class="icon">
				<icon icon="triangle-exclamation" />
			</span>
			<p>{{ emptyMsg }}</p>
		</div>
		<!-- Server error State -->
		<div class="is-server-error" v-if="currentState === TABLE_STATES.SERVER_ERROR">{{ serverErrorMsg }}</div>
	</div>
</template>

<script>
import CONSTANTS from "@/assets/js/constants"

export default {
	data() {
		return {
			TABLE_STATES: CONSTANTS.TABLE_STATES,
			currentState: CONSTANTS.TABLE_STATES.BASE_STATE
		}
	},
	props: {
		tableConfig: {
			default: []
		},
		tableData: {
			default: []
		},
		hasShadow: {
			type: Boolean,
			default: true
		},
		hasActions: {
			type: Boolean,
			default: false
		},
		emptyMsg: {
			type: String,
			default: "No data"
		},
		serverErrorMsg: {
			type: String,
			default: "Something went wrong. Please try again later."
		}
	},
	methods: {
		setCurrentState(newState) {
			this.currentState = newState
		}
	},
	computed: {
		canShowTable() {
			let result = false
			let { currentState, TABLE_STATES, tableData } = this

			if (currentState === TABLE_STATES.ACTIVE && tableData.length) result = true
			if (currentState === TABLE_STATES.RELOADING) result = true

			return result
		}
	}
}
</script>

<style lang="scss" scoped>
.table-wrapper {
	background-color: white;
	overflow: scroll;
	border-radius: $gap;
	width: 100%;
	margin: calc($gap * 2) 0;
	position: relative;
	min-height: 250px;

	display: flex;
	flex-direction: column;
}

.table-wrapper .loading {
	min-height: 400px;
	padding: calc($gap * 1.5) calc($gap * 2);

	display: flex;
	justify-content: flex-start;
	align-items: flex-start;

	button.button.is-loading {
		max-width: fit-content;
		max-height: fit-content;
		background-color: transparent;
	}
}
.table-wrapper .reloading {
	padding: calc($gap * 1.5) calc($gap * 2);

	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: hsla($background-h, 0%, 96%, 0.8);

	button.button.is-loading {
		max-width: fit-content;
		max-height: fit-content;
		background-color: transparent;
		&::after {
			border-color: hsla($background-h, 25%, 40%, 1);
			border-right-color: transparent;
			border-top-color: transparent;
		}
	}
}

.table-wrapper {
	.is-empty,
	.is-server-error {
		padding: calc($gap * 2);
		display: flex;
		height: 100%;
		width: 100%;
		justify-content: center;
		align-items: center;
		color: hsla($background-h, 25%, 50%, 0.5);
		font-weight: 600;
		flex-grow: 1;

		display: flex;
		flex-direction: column;

		.icon {
			height: 100px;
			width: 100px;
			svg {
				height: 100%;
				width: 100%;
			}
		}
	}
}

table {
	border-collapse: collapse;
	width: 100%;
	border: none;
	overflow: hidden;
	background-color: white;
	min-width: 800px;
	position: relative;
	font-size: 0.9rem;

	thead tr {
		text-align: left;
		font-weight: bold;
		background-color: hsl(0, 0%, 96%);
	}

	thead th {
		white-space: nowrap;
		&:hover {
			background-color: hsl(0, 0%, 94%);
		}
	}

	th {
		padding: calc($gap * 2);
		color: hsl(0, 0%, 40%);
	}

	td {
		padding: $gap calc($gap * 2);
	}

	tbody {
		tr {
			&:hover {
				background-color: hsl(0, 0%, 98%);
			}

			// td.table-row-item {
			// 	.button {
			// 		max-width: 44px !important;
			// 	}
			// }
		}
	}
}

.actions-wrapper {
	display: flex;
	justify-content: center;
	gap: calc($gap/2);
	height: 100%;
	padding: $gap calc($gap * 2);

	.action-item {
		width: fit-content;
		a {
			color: inherit;
		}
		.icon:hover {
			cursor: pointer;
		}

		&:hover {
			background-color: hsl(0, 0%, 93%);
		}
	}
}

.t-header {
	display: flex;
	flex-direction: column;
	.header-title {
		color: inherit;
	}
	.header-subtitle {
		color: hsl(0, 0%, 60%);
		font-weight: 400;
		font-size: 0.9rem;
	}
}
</style>
