<template>
	<div class="defend">
		<div class="defend-header">
			<div class="defend-header-title">
				<slot />
			</div>
		</div>
		<div class="defend-body" v-if="isEditable()">
			<InputBox
				:data="selectedTechniqueInfo[section.key]"
				type="markdown"
				:is-editable="true"
				field-type="textarea"
				@updated="updateSelectedTechniqueInfo(section.key, $event)"
			/>
		</div>
		<div class="defend-body" v-else v-html="$md.parse(selectedTechniqueInfo[section.key])"></div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import InputBox from "@/components/InputBox.vue"

export default {
	components: { InputBox },
	data() {
		return {
			modes: {
				edit: 0,
				preview: 1
			}
		}
	},
	props: {
		section: {
			type: [Array, Object],
			required: true
		}
	},
	methods: {
		...mapActions(["setSelectedTechniqueItem"]),
		updateSelectedTechniqueInfo(target, event) {
			this.setSelectedTechniqueItem({ target, event })
		},
		isEditable() {
			return this.isTechniqueEditable === this.modes.edit
		}
	},
	computed: {
		...mapGetters(["isTechniqueEditable", "selectedTechniqueInfo"])
	}
}
</script>

<style lang="scss" scoped>
.defend {
	.defend-header {
		.defend-header-title {
			font-size: 20px;
			font-weight: 700;
		}
	}
}
</style>