<template>
	<div class="content input-box">
		<div class="preview-mode" v-if="!isEditable">
			<div class="plaintext" v-if="isPlaintext()">{{ data }}</div>
			<div class="plaintext" v-if="isMarkdown()" v-html="$md.parse(data)"></div>
		</div>

		<div class="edit-mode" v-if="isEditable">
			<!-- Textarea element -->
			<textarea
				type="text"
				class="plaintext textarea"
				@blur="updateData($event)"
				@input="updateDataOnInput($event)"
				:value="data"
				v-if="fieldType == 'textarea'"
			/>

			<!-- Simple Input element  -->
			<input type="text" class="plaintext input" @blur="updateData($event)" :value="inputData" v-else />
		</div>
	</div>
</template>

<script>
const inputTypes = {
	plaintext: "plaintext",
	markdown: "markdown"
}

export default {
	data() {
		return {
			inputTypes: inputTypes,
			inputData: ""
		}
	},
	props: {
		data: {
			type: [Array, String, Object],
			default: "",
			required: true
		},
		type: {
			default: inputTypes.plaintext,
			required: true
		},
		fieldType: {
			type: [String, Number],
			required: false,
			default: "input"
		},
		isEditable: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		isPlaintext() {
			return this.type == this.inputTypes.plaintext
		},
		isMarkdown() {
			return this.type == this.inputTypes.markdown
		},
		updateData(event) {
			this.$emit("updated", event.target.value)
		},
		updateDataOnInput(event) {
			this.$emit("inputUpdated", event.target.value)
		}
	},
	updated() {
		this.$nextTick(() => (this.inputData = this.data))
	}
}
</script>

<style lang="scss" scoped>
$base-background: hsl(0, 0%, 90%);

.input-box {
	height: inherit;
	.preview-mode {
		height: 100%;
		.plaintext {
			display: flex;
			flex-direction: column;
			justify-content: center;

			height: 100%;
		}
	}
	.edit-mode {
		display: flex;
		align-items: center;
		height: 100%;
		.input {
			border-radius: calc($gap/2);
		}

		.textarea {
			width: 100%;
			height: 100%;
			min-height: 300px;
			box-sizing: border-box;
		}
	}

	input.input,
	textarea.textarea {
		box-shadow: none;
		background-color: transparent;
		border: 2px solid transparent;
		padding: $gap calc($gap);
		transition: border-color 150ms ease-in-out, background-color 150ms ease-in-out;
		padding: $gap;
		height: inherit;

		&:focus,
		&:focus-visible {
			border-color: hsl($background-h, $background-s, 60%);
			background-color: white;
		}

		&:hover {
			border-color: hsl($background-h, $background-s, 60%);
		}
	}
}
</style>