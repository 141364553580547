<template>
	<td class="table-row-item">
		<!-- Tag  -->
		<template v-if="type === 'tag'">
			<div class="tags-wrapper">
				<span :class="['tag is-light', 'is-' + tagType]">{{ value }}</span>
			</div>
		</template>

		<!-- Email  -->
		<template v-else-if="type === 'email'">
			<a :href="isClickable ? 'mailto:' + value : null" class="is-email">{{ value }}</a>
		</template>

		<!-- File Download  -->
		<template v-else-if="type === 'file-download'">
			<button @click="downloadFile()" class="button is-transparent">
				<span class="icon">
					<icon icon="download" />
				</span>
			</button>
		</template>

		<!-- file link from google storage https link -->
		<!-- download to disk using downloadFileFromURL method  -->
		<template v-else-if="type === 'file-link'">
			<button @click="downloadFileFromURL(value)" class="button is-transparent">
				<span class="icon">
					<icon icon="download" />
				</span>
			</button>
		</template>

		<!-- File Download  -->
		<template v-else-if="type === 'pdf-download'">
			<button
				@click="downloadPDFFile(fileName)"
				class="button is-fit-width"
				:disabled="isFetchingPDFFile"
				:class="btnClass"
			>
				<span class="icon">
					<span class="loader is-dark" v-if="isFetchingPDFFile"></span>
					<icon icon="download" v-else />
				</span>
				<span> {{ buttonTitle }} </span>
			</button>
		</template>

		<!-- Router Link  -->
		<template v-else-if="type === 'router-link'">
			<router-link class="button is-transparent" :to="{ name: value.route, params: { id: value.id } }">
				<span class="icon">
					<icon icon="download" />
				</span>
				<span class="ml-1">
					{{ value.id }}
				</span>
			</router-link>
		</template>

		<template v-else-if="type === 'plaintext'">
			<div
				:class="{ clipboard: clipboard }"
				ref="plaintext"
				@click="copyToClipboard(value)"
				@mouseleave="copiedToClipboard = false"
			>
				<span class="plaintext">{{ value || "-" }}</span>
				<span v-if="clipboard" class="clipboard-icon">
					<icon v-if="!copiedToClipboard" icon="clone" />
					<icon v-else icon="check-circle" class="fade-in-left" />
				</span>
			</div>
		</template>
		<template v-else>
			<slot />
		</template>
	</td>
</template>

<script>
import axios from "axios"
export default {
	data() {
		return {
			copiedToClipboard: false,
			isFetchingPDFFile: false
		}
	},
	props: {
		value: {
			type: [String, Number, Boolean],
			required: false,
			default: "-"
		},
		endPoint: {
			type: [String, Number, Boolean],
			required: false,
			default: "-"
		},
		payload: {
			type: [String, Number, Boolean, Object],
			required: false,
			default: ""
		},
		type: {
			type: [String, Object, Array],
			default: "",
			validator: value => {
				return ["", "plaintext", "tag", "email", "file-download", "pdf-download", "router-link", "file-link"].includes(
					value
				)
			}
		},
		tagType: {
			type: String,
			default: "primary",
			validator: value => {
				return ["primary", "link", "info", "success", "warning", "danger"].includes(value)
			}
		},
		isClickable: {
			type: Boolean,
			default: true
		},
		fileName: {
			type: String,
			default: "application",
			required: false
		},
		fileExtension: {
			type: String,
			default: null,
			required: false
		},
		clipboard: {
			type: Boolean,
			default: false,
			required: false
		},
		buttonTitle: {
			type: String,
			default: "Download",
			required: false
		},
		btnClass: {
			type: String,
			default: "",
			required: false
		}
	},
	methods: {
		downloadFile(fileName) {
			let { fileExtension, value } = this
			const link = document.createElement("a")

			link.setAttribute("href", value)
			link.setAttribute("download", `${fileName}.${fileExtension}`)
			link.click()
		},
		downloadPDFFile(fileName) {
			let { fileExtension, payload } = this
			let method = payload ? "POST" : "GET"

			this.isFetchingPDFFile = true

			axios({
				url: this.endPoint,
				data: payload,
				method: method,
				responseType: "blob"
			})
				.then(response => {
					const blob = response.data
					const link = document.createElement("a")

					link.href = window.URL.createObjectURL(blob)
					link.download = `${fileName}.${fileExtension}`
					link.click()

					this.isFetchingPDFFile = false
				})
				.catch(err => {
					console.log("err", err)
					this.isFetchingPDFFile = false
				})
		},
		copyToClipboard(text) {
			if (!this.clipboard) return

			if (navigator.clipboard) {
				navigator.clipboard.writeText(text).catch(err => {
					console.error("Failed to copy to clipboard:", err)
				})
			} else {
				const input = document.createElement("input")
				input.value = text
				input.style.position = "absolute"
				input.style.left = "-9999px"
				input.style.top = "-9999px"
				document.body.appendChild(input)
				input.select()
				document.execCommand("copy")
				document.body.removeChild(input)
			}

			this.copiedToClipboard = true
			// setTimeout(() => {
			// 	this.copiedToClipboard = false
			// }, 1000)
		},
		downloadFileFromURL(url) {
			let newURL = new URL(url)

			// split pathname by '/' into array and use the last value as filename
			let fileName = newURL.pathname.split("/").pop()

			// Download the file using axios
			axios
				.get(url, {
					method: "GET",
					responseType: "blob"
				})
				.then(response => {
					let blob = response.data
					let link = document.createElement("a")
					link.href = window.URL.createObjectURL(blob)
					link.download = fileName
					link.click()
				})
		}
	}
}
</script>

<style lang="scss" scoped>
td.table-row-item {
	padding: $gap calc($gap * 2);
	.button {
		width: fit-content !important;
		max-width: auto !important;
	}
}

.tags-wrapper {
	display: flex;
	flex-wrap: nowrap;
	gap: calc($gap/2);
}

.is-email {
	color: inherit;

	&:hover {
		text-decoration: underline;
	}
}

.clipboard {
	cursor: pointer;
	&:hover {
		.plaintext {
			text-decoration-line: underline;
			text-decoration-style: dashed;
		}

		.clipboard-icon {
			visibility: visible;
		}
	}

	.clipboard-icon {
		visibility: hidden;
		margin-left: calc($gap/2);
		height: 14px;
		width: 14px;
	}
}
</style>
